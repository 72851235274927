<template>
  <v-row
    class="d-flex align-center"
  >
    <v-col
      cols="12"
      sm="3"
      class="py-md-4 py-1"
    >
      <div
        class="d-flex align-center "
        :class="{'flex-row justify-space-between w-full':$vuetify.breakpoint.smAndDown, 'flex-column px-2': $vuetify.breakpoint.mdAndUp}"
      >
        <p class="text-body-1 text-center item text-no-wrap text--darken-3 pl-1 mb-0 pb-0">
          {{ $t('labels.acquisition-booster.invitations') }}
        </p>
        <v-progress-circular
          v-if="!hasInvitationsCount"
          indeterminate
          :size="25"
        />
        <p
          v-else
          class="mb-0 pb-0"
        >
          <b>{{ totalInvitationsCount }}</b>
        </p>
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      class="py-md-4 py-1"
    >
      <div
        class="d-flex align-center"
        :class="{'flex-row justify-space-between w-full':$vuetify.breakpoint.smAndDown, 'flex-column px-2': $vuetify.breakpoint.mdAndUp}"
      >
        <p class="text-body-1 text-center item text-no-wrap text--darken-3 pl-1 mb-0 pb-0">
          {{ $t('labels.acquisition-booster.interactions') }}
        </p>
        <v-progress-circular
          v-if="!hasInvitationsCount"
          indeterminate
          :size="25"
        />
        <p
          v-else
          class="mb-0 pb-0"
        >
          <b>{{ interactedInvitationsCount }}</b>
        </p>
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      class="py-md-4 py-1"
    >
      <div
        class="d-flex align-center"
        :class="{'flex-row justify-space-between w-full':$vuetify.breakpoint.smAndDown, 'flex-column px-2': $vuetify.breakpoint.mdAndUp}"
      >
        <p class="text-body-1 text-center item text-no-wrap text--darken-3 pl-1 mb-0 pb-0">
          {{ $t('labels.acquisition-booster.time-saved') }}
        </p>
        <v-progress-circular
          v-if="!hasInvitationsCount"
          indeterminate
          :size="25"
        />
        <p
          v-else
          class="mb-0 pb-0"
        >
          <b>{{ timeSaved }}</b>
        </p>
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      class="py-md-4 py-1"
    >
      <div
        class="d-flex align-center"
        :class="{'flex-row justify-space-between w-full':$vuetify.breakpoint.smAndDown, 'flex-column px-2': $vuetify.breakpoint.mdAndUp}"
      >
        <p class="text-body-1 text-center item text-no-wrap text--darken-3 pl-1 mb-0 pb-0">
          {{ $t('labels.acquisition-booster.money-saved') }}
        </p>
        <v-progress-circular
          v-if="!hasInvitationsCount"
          indeterminate
          :size="25"
        />
        <p
          v-else
          class="mb-0 pb-0"
        >
          <b>{{ moneySaved }}</b>
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import currency from '@/mixins/currency'
import feature from '@/mixins/feature'
const MINUTES_PER_MAIL = 25
const MONEY_PER_MINUTE = 2

export default {
  mixins: [currency, feature],
  props: {
    totalInvitationsCount: {
      type: Number,
      default: 0
    },
    interactedInvitationsCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hasInvitationsCount () {
      return this.totalInvitationsCount >= 0
    },
    factors () {
      const { minutesPerMail, moneyPerMinute } = this.$features.feature(this.featureNames.ACQUISITION_BOOSTER)?.config?.dashboard
      return {
        minutesPerMail: minutesPerMail || MINUTES_PER_MAIL,
        moneyPerMinute: moneyPerMinute || MONEY_PER_MINUTE
      }
    },
    timeSaved () {
      return this.totalInvitationsCount && this.formatTime(this.totalInvitationsCount * this.factors.minutesPerMail)
    },
    moneySaved () {
      return this.totalInvitationsCount && this.formatPrice(this.totalInvitationsCount * this.factors.minutesPerMail * this.factors.moneyPerMinute)
    }
  },
  methods: {
    formatTime (minutes) {
      return `${Math.round(minutes / 60)} h`
    }
  }
}
</script>
